.footerCont {
  background-color: #000342;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 7.2rem 8rem;
  color: white;
}

.menusSec {
  width: 100%;
  /* background-color: orange; */
  display: flex;
  justify-content: space-evenly;

  /* justify-content: space-between; */
  gap: 3rem;
  margin-bottom: 2rem;
}

.menuSec {
  /* background-color: blue; */
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

.menuSecInner {
  /* background-color: rgb(5, 68, 48); */

  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 3rem;
}

.menuHeading {
  /* background-color: pink; */
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  white-space: nowrap;

  /* text-align: center; */
}

.footerBottomSec {
  /* background-color: olive; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.smIconsCont {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.copyrights {
  font-size: 1.8rem;
}

.aawazWhiteLogo {
  width: 25%;
}

.joinCont {
  /* background-color: #000342; */
  background-image: url("../../assets/images/joinBgImageMin.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* gap: 5rem; */
  padding: 12rem 2.5rem;
}

.joinText {
  font-size: 6.4rem;
  font-weight: 700;
  color: white;
  margin-bottom: 6rem;
  text-align: center;
}
.joinPara {
  font-size: 2rem;
  font-weight: 500;
  color: white;
  margin-bottom: 4.5rem;
}

.emailSubCont {
  background-color: white;
  width: 40%;
  display: flex;
  align-items: center;
  border-radius: 1.6rem;
  margin-bottom: 2rem;
}

.emailSub1 {
  /* background-color: aqua; */
  width: 75%;
  border-radius: 1.6rem 0 0 1.6rem;
  padding: 0 2.2rem;
}
.emailSub2 {
  background-color: var(--primary);
  width: 25%;
  border-radius: 1.6rem;
  padding: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}
.emailSub2:hover {
  background-color: #ff2485;
}

.subPara {
  color: white;
}
.subBtn {
  color: white;
}

.loader {
  border: 4px solid #ffffff; /* Light grey */
  border-top: 4px solid var(--primary); /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.input {
  width: 100%;
  font-size: 1.8rem;
  font-weight: 500;
  color: black;
}

.subPara {
  color: white;
}

.modal {
  /* background-color: blue; */
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 8888;
  padding-top: 6rem;
}

/* Optional: Set a maximum width for the modal */
.modalCont {
  background-color: var(--light);
  width: 50%;
  padding: 2rem 2rem;
  border: 1px solid var(--border);
  overflow: hidden;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.sureCont {
  /* background-color: var(--bgClrModal); */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem 0rem;
}

.sureTextCont {
  /* background-color: brown; */
  flex: 1;
  font-size: 2.4rem;
  font-weight: 600;
  color: var(--primary);
}

.crossIconCont {
  width: 5%;
  /* background-color: olive; */
  display: flex;
  align-self: flex-end;
  cursor: pointer;
}

.subImgCont {
  width: 25%;
}

.text {
  font-size: 1.8rem;
  color: #3c3c3c;
  padding: 2rem 2.5rem;
}

/* Optional: Style for the buttons in the modal */
.modalButtons {
  /* background-color: aqua; */
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 1.5rem;
  margin-bottom: 2.4rem;
}

.cancelBtn {
  background-color: transparent;
  color: var(--dark);
}
.cancelBtn:hover {
  background-color: transparent;
  color: var(--dark);
}

.exitBtn {
  background-color: transparent;
  color: var(--primary);
  border: 1px solid var(--primary);
}
.exitBtn:hover {
  background-color: var(--primary);
  color: var(--light);
  border: 1px solid var(--primary);
}

@media screen and (max-width: 767px) {
  .footerCont {
    display: none;
  }
  .joinText {
    font-size: 4rem;
  }
  .emailSubCont {
    width: 100%;
  }
  .joinPara {
    text-align: center;
  }

  .emailSub2 {
    padding: 0.8rem 4rem;
  }

  .copyrights {
    font-size: 1.4rem;
  }

  .footerBottomSec {
    margin-top: 4rem;
  }

  .modalCont {
    width: 80%;
  }

  .text {
    padding: 2rem 0rem;
    font-size: 1.4rem;
  }
  .crossIconCont {
    width: 10%;
  }
  .subImgCont {
    width: 50%;
  }
  .sureTextCont {
    font-size: 1.8rem;
  }
}

.mainCont {
  /* background-color: orange; */
  width: 100%;
  /* height: 100vh; */
  display: flex;
}

.sec1 {
  /* background-color: green; */

  flex: 1;
  padding: 7rem 13rem;
}

.sec1Cont {
  /* background-color: rgb(173, 198, 198); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loginText {
  margin-top: 2rem;
  margin-bottom: 6rem;
}

.loginForm {
  /* background-color: blanchedalmond; */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.input {
  width: 100%;
  border: 1px solid var(--border);
  border-radius: 1rem;
  color: none;
  padding: 0.5rem 1rem;
  /* font-style: italic; */
  letter-spacing: 0.1rem;
}

.createBtn {
  background-color: var(--primary);
  /* font-size: 1.6rem; */
  font-weight: 600;
  padding: 1.2rem 1.8rem;
  border-radius: 0.8rem;
  color: white;
  white-space: nowrap;
}

.createBtn:hover {
  background-color: #ff2485;
}

/* .inputPass {
  font-style: italic;
  letter-spacing: 0.1rem;
} */

.passEye {
  width: 100%;
  border: 1px solid var(--border);
  border-radius: 1rem;
  color: none;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sec2 {
  background-color: var(--bgClr);
  flex: 1;
  padding: 9rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.namesCont {
  /* background-color: aqua; */
  display: flex;
}
.nameCont {
  flex: 1;
}

.continueBtnCont {
  /* background-color: aquamarine; */
  margin-top: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoIconBig {
  /* background-color: olive; */

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .mainCont {
    display: flex;
    flex-direction: column;
  }

  .sec1 {
    padding: 2rem 4rem;
  }

  .sec1cont {
    width: 100%;
  }

  .sec2 {
    padding: 2rem;
  }

  .logoIconBig {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.mainCont {
  display: flex;
  /* height: 100vh; */
  /* background-color: var(--light); */
  background-color: #f7f9fe;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;
  /* box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.08); */

  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  /* background-color: #333; */
  z-index: 1000;
}

.navLinks {
  /* background-color: orange; */
  list-style: none;
  display: flex;
  align-items: center;
  gap: 3rem;
}

.navLink {
  font-size: 1.4rem;
}

.navLinkAmal {
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--primary);
}

.navLink.activeLink {
  position: relative;
}

.navLink.activeLink::after {
  content: "";
  display: block;
  width: 100%;
  height: 0.15rem; /* Adjust the line thickness as needed */
  background-color: var(--primary); /* Adjust the line color as needed */
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  transition: width 0.3s ease; /* Optional: Add a smooth transition effect */
}

.navLink.activeLink:hover::after {
  width: 100%; /* Expand the line on hover */
}

.activeLink {
  color: var(--primary);
}

.activeLink:hover {
  background-color: var(--light);
}

.navImgComp {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.bellImageCont {
  display: flex;
  align-items: center;
  gap: 2.8rem;
  margin-left: 12rem;
}

.dropImage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.6rem;
}

.circularDiv {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  background-color: #e5edff;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .mainCont {
    padding: 0.7rem 2rem;
  }
  .navLink {
    font-size: 1.2rem;
  }
}

/* CircularImage.module.css */

.circularImageWrapper {
  width: 3rem; /* Adjust the size as needed */
  height: 3rem;
  overflow: hidden;
  border-radius: 50%; /* Creates a circular shape */
  /* border: 2px solid #fff;  */
  cursor: pointer;
}

.circularImage {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire circular area */
  border-radius: 50%; /* Keeps the image circular within the border-radius of the parent */
}

/* Add this to CircularImageComp.module.css */

.dropdown {
  /* background-color: orange; */

  border-radius: 1rem;
  position: absolute;
  top: 100%; /* Position the dropdown below the circular image */
  right: 1px;
  width: 15%;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  padding: 1rem 0;
  display: block;
}

.circularImageWrapper:hover .dropdown {
  display: block;
}

.dropdownItem {
  /* background-color: aqua; */
  padding: 1.3rem 0 1.3rem 2rem;
  border-bottom: 1px solid var(--border);
  /* font-size: 1.4rem; */
}
.logoutItem {
  /* background-color: aqua; */
  padding: 1.3rem 0 1.3rem 2rem;
  border-bottom: 1px solid var(--border);
  /* font-size: 1.4rem; */
  border: none;
}

.dropdownCont {
  /* background-color: orange; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

@media screen and (max-width: 767px) {
  .dropdown {
    width: 30%;
  }
}

.footerCont {
  background-color: #000342;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 7.2rem 8rem;
  color: white;
  margin-top: 5rem;
}

.menusSec {
  width: 100%;
  /* background-color: orange; */
  display: flex;
  justify-content: space-evenly;

  /* justify-content: space-between; */
  gap: 3rem;
  margin-bottom: 2rem;
}

.menuSec {
  /* background-color: blue; */
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

.menuSecInner {
  /* background-color: rgb(5, 68, 48); */

  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 3rem;
}

.menuHeading {
  /* background-color: pink; */
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  white-space: nowrap;

  /* text-align: center; */
}

.footerBottomSec {
  /* background-color: olive; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.smIconsCont {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.copyrights {
  font-size: 1.8rem;
}

.aawazWhiteLogo {
  width: 25%;
}

.joinCont {
  /* background-color: #000342; */
  background-image: url("../../assets/images/joinBgImageMin.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* gap: 5rem; */
  padding: 12rem 2.5rem;
}

.joinText {
  font-size: 6.4rem;
  font-weight: 700;
  color: white;
  margin-bottom: 6rem;
  text-align: center;
}
.joinPara {
  font-size: 2rem;
  font-weight: 500;
  color: white;
  margin-bottom: 4.5rem;
}

.emailSubCont {
  background-color: white;
  width: 40%;
  display: flex;
  align-items: center;
  border-radius: 1.6rem;
  margin-bottom: 2rem;
}

.emailSub1 {
  /* background-color: aqua; */
  width: 75%;
  border-radius: 1.6rem 0 0 1.6rem;
  padding: 0 2.2rem;
}
.emailSub2 {
  background-color: var(--primary);
  width: 25%;
  border-radius: 1.6rem;
  padding: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}
.emailSub2:hover {
  background-color: #ff2485;
}

.subPara {
  color: white;
}
.subBtn {
  color: white;
}

.loader {
  border: 4px solid #ffffff; /* Light grey */
  border-top: 4px solid var(--primary); /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.input {
  width: 100%;
  font-size: 1.8rem;
  font-weight: 500;
  color: #808080;
}

.subPara {
  color: white;
}

@media screen and (max-width: 767px) {
  .footerCont {
    padding: 6rem 2rem;
    margin-top: 3rem;
  }
  .joinText {
    font-size: 4rem;
  }
  .emailSubCont {
    width: 100%;
  }
  .joinPara {
    text-align: center;
  }

  .emailSub2 {
    padding: 0.8rem 4rem;
  }

  .hide {
    display: none;
  }

  .menuSecInner {
    align-items: center;
    margin-bottom: 3rem;
    /* justify-content: center; */
  }
  .copyrights {
    font-size: 1.4rem;
  }

  .footerBottomSec {
    margin-top: 4rem;
  }

  .menusSec {
    display: flex;
    flex-direction: column;
  }
}

/* Navbar.module.css */

.mainCont {
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 8rem 1rem 8rem;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  border-bottom: 1px solid #f2f4f7;
}

.mainContBlueBg {
  background-color: #000342;
  color: white;
  border-bottom: 1px solid #000342;
}

.subMainContBlueBg {
  background-color: orange;
  /* width: 100%; */
}

.navAndBtns {
  width: 100%;
  /* background-color: pink; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navLinks {
  /* background-color: olive; */
  width: 80%;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.6rem;
}

.navLink {
  /* font-size: 1.6rem; */
  font-weight: 500;
  color: var(--gray);
}

.subMenuProducts {
  /* Your styles for submenu */
  background-color: white;
  position: absolute;
  top: 100%;
  left: 29%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 1.2rem;
  padding: 3.2rem;
  border: 1px solid #eaecf0;
  /* display: none; */
}
.subMenuProductsBlue {
  /* Your styles for submenu */
  background-color: #000342;
  position: absolute;
  top: 100%;
  left: 29%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 1.2rem;
  padding: 3.2rem;
  border: 1px solid #eaecf0;
  /* display: none; */
}
.subMenuUsers {
  /* Your styles for submenu */
  background-color: white;
  position: absolute;
  top: 100%;
  left: 32%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 1.2rem;
  padding: 3.2rem;
  border: 1px solid #eaecf0;
  /* display: none; */
}
.subMenuUsersBlue {
  /* Your styles for submenu */
  background-color: #000342;
  position: absolute;
  top: 100%;
  left: 32%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 1.2rem;
  padding: 3.2rem;
  border: 1px solid #eaecf0;
  /* display: none; */
}
.subMenuAbout {
  /* Your styles for submenu */
  background-color: white;
  position: absolute;
  top: 100%;
  left: 53%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 1.2rem;
  padding: 3.2rem;
  border: 1px solid #eaecf0;
  /* display: none; */
}
.subMenuAboutBlue {
  /* Your styles for submenu */
  background-color: #000342;
  position: absolute;
  top: 100%;
  left: 50%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 1.2rem;
  padding: 3.2rem;
  border: 1px solid #eaecf0;
  /* display: none; */
}

.subMenuProducts:hover,
.subMenuUsers:hover,
.subMenuAbout:hover,
.subMenuProductsBlue:hover,
.subMenuUsersBlue:hover,
.subMenuAboutBlue:hover {
  padding-bottom: 30px; /* Adjust this value as needed */
  position: absolute;
}

.subNavLink {
  font-weight: 500;
}

/* Show submenu on hover */
/* .navContainer li:hover .subMenu {
  display: block;
} */

.navLinkBlueBg {
  color: white;
}

.navLink.activeLink {
  position: relative;
}

.navLink.activeLink::after {
  content: "";
  display: block;
  width: 100%;
  height: 0.15rem;
  background-color: var(--primary);
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  transition: width 0.3s ease;
}

.navLink.activeLink:hover::after {
  width: 100%;
}

.activeLink {
  color: var(--primary);
}

.activeLink:hover {
  background-color: var(--light);
}

.btnsCont {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.2rem;
}

.loginBtn {
  /* font-size: 1.6rem; */
  font-weight: 600;
  color: #475467;
}

.loginBtnBlueBg {
  color: white;
}

.loginBtn:hover {
  color: var(--primary);
}

.createBtn {
  background-color: var(--primary);
  /* font-size: 1.6rem; */
  font-weight: 600;
  padding: 1.2rem 1.8rem;
  border-radius: 0.8rem;
  color: white;
  white-space: nowrap;
}

.createBtn:hover {
  background-color: #ff2485;
}

.hamBtnWrap {
  display: none; /* Hide hamburger menu button by default */
}

.aawazWhiteLogo {
  height: 6rem;
}

@media screen and (max-width: 767px) {
  .mainCont {
    width: 100%;
    /* background-color: pink; */
    padding: 2rem 2rem 2rem 2rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: none;
  }

  .navAndBtns {
    display: none; /* Hide navigation links and buttons for mobile view */
  }

  .hamBtnWrap {
    display: flex; /* Display hamburger menu button for mobile view */
    /* background-color: olivedrab; */
    justify-content: flex-end;
    align-items: center;
    padding: 0rem 0;
    cursor: pointer;
  }

  .mobileMenu {
    /* background-color: orange; */
    width: 100%;
    display: flex; /* Display mobile menu when hamburger menu button is clicked */
    flex-direction: column;

    gap: 4rem;
    border: 1px solid #f2f4f7;
    padding: 2rem;
    border-radius: 1rem;
    margin-top: 2rem;
  }

  .navLinks {
    /* background-color: orange; */
    width: 100%;
    display: flex; /* Display nav links in mobile menu for mobile view */
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: flex-start; */
    gap: 1rem;
  }

  .navLink {
    font-size: 1.4rem;
  }

  .btnsCont {
    /* background-color: orange; */
    width: 100%;
    display: flex; /* Display buttons in mobile menu for mobile view */
    flex-direction: column;
    gap: 1rem;
  }

  .createBtn {
    width: 100%;
  }

  /* Add this CSS to your existing stylesheet */

  .mobileMenu {
    position: relative; /* Ensure the mobile menu container has a relative position */
  }

  .navLinks {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .navLinks > li {
    position: relative; /* Add relative positioning to parent menu items */
  }

  /* Remove the hover styles for submenu display */
  .subMenuProducts,
  .subMenuUsers,
  .subMenuAbout {
    /* background-color: olive; */
    width: 100%;

    position: absolute;
    top: 100%;
    left: 0;
    display: none; /* Hide submenus by default */
  }

  /* Add a class to show submenus when the parent menu item is clicked */
  .subMenuOpen {
    /* background-color: olivedrab; */
    padding: 3rem 1.6rem;
    /* margin: 0; */
    z-index: 99;
    display: block; /* Display submenus when the parent menu item is clicked */
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .subNavLink {
    font-size: 1.6rem;
    font-weight: 600;
  }
  .navLinkMobile {
    /* background-color: olivedrab; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* gap: 10rem; */
  }
}

.mainCont {
  /* background-color: orange; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5.7rem 0 0 0;
}

.sec1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20rem;
  margin-bottom: 3.7rem;
}

.sorryText {
  font-size: 3.6rem;
  font-weight: 600;
  color: #575757;
  text-align: center;
  margin-top: 3.7rem;
  margin-bottom: 3.7rem;
}

.accessIconCont {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.accessText {
  font-size: 3.6rem;
  font-weight: 600;
  color: var(--primary);
}

.sec2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 8rem;
}

.joinPara {
  font-size: 2.6rem;
  font-weight: 600;
  color: #979797;
  text-align: center;
  margin-bottom: 5rem;
}

.emailSubCont {
  background-color: white;
  width: 55%;
  display: flex;
  align-items: center;
  border-radius: 1.6rem;
  margin-bottom: 2rem;
  box-shadow: 0px 2px 10px 0px #00000026;
}

.emailSub1 {
  /* background-color: aqua; */
  width: 70%;
  border-radius: 1.6rem 0 0 1.6rem;
  padding: 0 2.2rem;
}
.emailSub2 {
  background-color: var(--primary);
  width: 30%;
  border-radius: 1.6rem;
  padding: 1.5rem 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}
.emailSub2:hover {
  background-color: #ff2485;
}

.subPara {
  color: white;
  margin-bottom: 6rem;
}
.subBtn {
  color: white;
  white-space: nowrap;
  font-size: 1.8rem;
  font-weight: 500;
}

.loader {
  border: 4px solid #ffffff; /* Light grey */
  border-top: 4px solid var(--primary); /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.input {
  width: 100%;
  font-size: 1.8rem;
  font-weight: 500;
  color: black;
  /* color: #808080; */
}

.subPara {
  color: var(--primary);
}

.jwa1 {
  width: 35%;
}

.modal {
  /* background-color: blue; */
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 8888;
  padding-top: 6rem;
}

/* Optional: Set a maximum width for the modal */
.modalCont {
  background-color: var(--light);
  width: 50%;
  padding: 2rem 2rem;
  border: 1px solid var(--border);
  overflow: hidden;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.sureCont {
  /* background-color: var(--bgClrModal); */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem 0rem;
}

.sureTextCont {
  /* background-color: brown; */
  flex: 1;
  font-size: 2.4rem;
  font-weight: 600;
  color: var(--primary);
}

.crossIconCont {
  width: 5%;
  /* background-color: olive; */
  display: flex;
  align-self: flex-end;
  cursor: pointer;
}

.subImgCont {
  width: 25%;
}

.text {
  font-size: 1.8rem;
  color: #3c3c3c;
  padding: 2rem 2.5rem;
}

/* Optional: Style for the buttons in the modal */
.modalButtons {
  /* background-color: aqua; */
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 1.5rem;
  margin-bottom: 2.4rem;
}

.cancelBtn {
  background-color: transparent;
  color: var(--dark);
}
.cancelBtn:hover {
  background-color: transparent;
  color: var(--dark);
}

.exitBtn {
  background-color: transparent;
  color: var(--primary);
  border: 1px solid var(--primary);
}
.exitBtn:hover {
  background-color: var(--primary);
  color: var(--light);
  border: 1px solid var(--primary);
}

@media screen and (max-width: 767px) {
  .mainCont {
    padding: 4rem 0 0 0;
  }
  .jwa1 {
    width: 80%;
  }
  .footerCont {
    display: none;
  }
  .joinText {
    font-size: 4rem;
  }
  .emailSubCont {
    width: 100%;
  }
  .joinPara {
    text-align: center;
  }

  .emailSub2 {
    padding: 0.8rem 4rem;
  }

  .sec1 {
    padding: 0 2rem;
  }
  .sec2 {
    padding: 0 2rem;
  }
  .sorryText {
    font-size: 3rem;
  }
  .accessText {
    font-size: 2.5rem;
    white-space: nowrap;
  }
  .joinPara {
    font-size: 2rem;
  }

  .emailSub1 {
    width: 55%;
    padding: 0 1rem;
  }

  .input {
    font-size: 1.6rem;
  }
  .emailSub2 {
    width: 45%;
  }

  .subBtn {
    font-size: 1.2rem;
  }

  .modalCont {
    width: 80%;
  }

  .text {
    padding: 2rem 0rem;
    font-size: 1.4rem;
  }
  .crossIconCont {
    width: 10%;
  }
  .subImgCont {
    width: 50%;
  }
  .sureTextCont {
    font-size: 1.8rem;
  }
}

.ascendBtnCont {
  background-color: var(--primary);

  padding: 1.6rem 2.6rem;
  border-radius: 1.6rem;
  color: white;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 2rem;
  cursor: pointer;
}

.ascendBtnCont:hover {
  background-color: #ff2485;
}

.joinBtnText {
  font-size: 2rem;
  font-weight: 500;
}

.backArrow {
  width: 4.5rem;
}

@media screen and (max-width: 767px) {
  .ascendBtnCont {
    background-color: var(--primary);

    padding: 1.5rem 2.5rem;
  }
}

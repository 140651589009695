.cardWhite:hover {
  border-bottom: 3px solid var(--primary);
  border-right: 3px solid var(--primary);
  border-top: 3px solid transparent;
  border-left: 3px solid transparent;
}
.cardVictory:hover {
  border-bottom: 3px solid var(--primary);
  border-right: 3px solid var(--primary);
  border-top: 3px solid transparent;
  border-left: 3px solid transparent;
}
.cardPink:hover {
  border-bottom: 3px solid white;
  border-right: 3px solid white;
  border-top: 3px solid transparent;
  border-left: 3px solid transparent;
}
.image {
  width: 100%;
}

.title {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 2rem;
}
.titleWhite {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 2rem;
  color: white;
}
.headline {
  font-size: 2rem;
  font-weight: 500;
  color: var(--headline);
  margin-bottom: 2.5rem;
}
.headlineWhite {
  font-size: 2rem;
  color: white;
  margin-bottom: 2.5rem;
}
.desc {
  font-size: 2rem;
  font-weight: 500;
  /* text-align: justify; */
}
.descWhite {
  font-size: 2rem;
  color: white;
  /* text-align: justify; */
}

.bootcampMainImage {
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.mainCont {
  /* background-color: orange; */
  /* padding: 4rem 6rem 0 6rem; */
}
.imgCont {
  /* background-color: beige; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 6rem;
  padding: 18rem 12rem;
}
.mainImage {
  /* height: 100%; */
  width: 65%;
}

.sec1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spansCont {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 2rem;
  margin-bottom: 10rem;
  padding: 18rem 12rem;
}

.fuelHeading1 {
  font-size: 6.4rem;
  color: #2d2d2d;
  font-weight: 700;
}
.fuelHeading2 {
  font-size: 6.4rem;
  color: #ff0071;
  font-weight: 700;
}

.ascendBtnCont {
  background-color: var(--primary);

  padding: 2.8rem 4.2rem;
  border-radius: 1.6rem;
  color: white;
  margin-bottom: 8.2rem;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.joinBtnText {
  font-size: 2rem;
  font-weight: 500;
}

.backArrow {
  width: 4.5rem;
}

.imgsCont {
  /* background-color: azure; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8rem;
  margin-bottom: 11rem;
}

.bankImages {
  width: 20%;
  height: 20%;
}

.weAscendCont {
  background-color: #000342;
  width: 100%;
  /* height: 100vh; */
  display: flex;
  /* flex-wrap: wrap; */
  /* align-items: center; */
  gap: 2rem;
  padding: 18rem 12rem;
}

.topSec {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 18rem 12rem;
}

.weAscend1 {
  /* background-color: orange; */
  flex: 1;
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  gap: 2rem;
  clear: both;
}

.weAscend1Top {
  /* background-color: pink; */
  /* height: 100%; */
  display: flex;
  gap: 2rem;
  /* flex-direction: column; */
  /* overflow: auto; */
}

.weAscend1Top1 {
  /* background-color: aqua; */
  width: 30%;

  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.bootcampCont {
  background-color: white;

  /* min-height: 55%; */
  display: flex;
  flex-direction: column;
  border-radius: 3.2rem;
  padding: 3rem 2.4rem;
}

.icon4xCont {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.5rem;
  margin-bottom: 5rem;
}

.learnMore {
  /* background-color: #ff0071; */
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
  padding-right: 3rem;
  /* font-size: 1.6rem; */
  font-weight: 500;
  color: var(--pink-dim);
}

/* Define the container for the image and text */
.victoryImgCont {
  position: relative; /* Establishes the positioning context */
}

/* Style for the image */
.victoryImg {
  width: 100%;
  border-radius: 3.6rem;
}

/* Define the keyframes for the rotation animation */
@keyframes rotateInCircle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Apply initial styles to the circular text */
.victoryCircleText {
  /* background-color: orange; */
  position: absolute;
  bottom: 5%;
  right: 5%;
  transition: transform 0.5s ease; /* Add transition for smooth animation */
  animation: none; /* Initially, no animation */
}

/* Apply rotation animation when victoryImg is hovered */
.victoryImgCont:hover .victoryCircleText {
  animation: rotateInCircle 5s linear infinite; /* Apply the rotation animation */
}
/* .victoryImgCont:hover .victoryCircleText ~ .cardVictory {
    border-bottom: 3px solid var(--primary);
    border-right: 3px solid var(--primary);
    border-top: 3px solid transparent;
    border-left: 3px solid transparent;
  } */

.weAscend1Top2 {
  /* background-color: olive; */
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.percentWsmesCont {
  display: flex;
  gap: 2rem;
  /* height: 30%; */
}
.percentWsmesCont1 {
  background-color: var(--primary);
  color: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;

  border-radius: 3.2rem;
  padding: 3rem 2.4rem;
}

.percentCont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.percentContTrillion {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.percentText {
  width: 30%;
  font-size: 3rem;
  font-weight: 700;
}
.percentTextTn1 {
  font-size: 3rem;
  font-weight: 700;
}
.percentText2 {
  /* background-color: rgb(34, 143, 107); */
  flex: 1;
  font-size: 1.8rem;
  font-weight: 500;
}
.percentWsmesCont2 {
  background-color: white;

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  border-radius: 3.2rem;
  padding: 3rem 2.4rem;
}
.trillion {
  color: #39beab;
  font-size: 6.5rem;
  font-weight: 700;
  text-align: center;
}

.estimated {
  font-size: 1.8rem;
  font-weight: 500;
}

.logoCont {
  background-color: white;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3.2rem;
}

/* .logoCont:hover {
    scale: 1.1;
    background-color: olive;
  } */

.ascendLogoWrap {
  /* background-color: orange; */
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoCont:hover .ascendLogoWrap {
  width: 75%;
}

.weAscend1Bottom {
  /* background-color: lightblue; */
  display: flex;
  gap: 2rem;
  /* clear: both; */
  /* height: 100%; */
}

.compass {
  background-color: var(--primary);
  color: white;

  width: 55%;
  border-radius: 3.2rem;
  padding: 3rem 2.4rem;
  display: flex;
  flex-direction: column;
}

.learnMoreCont {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 1.3rem;
}

.learnMoreWhite {
  /* background-color: olive; */
  color: white;
  font-weight: 500;
  text-align: left;
}

.circle {
  background-color: white;
  width: 45%;
  border-radius: 3.2rem;
  padding: 3rem 2.4rem;
  display: flex;
  flex-direction: column;
}

.womenInCirclesPlus {
  width: 17rem;
  margin-top: 1.5rem;
}

.learnMorePink {
  color: var(--primary);
}

.weAscend2 {
  /* background-color: green; */
  width: 25%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.credit {
  background-color: white;
  height: 50%;
  border-radius: 3.2rem;
  padding: 3rem 2.4rem;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.creditedByCont {
  padding: 1.8rem 1.4rem;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 0.8rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lockProfileCont {
  /* background-color: #ff0071; */
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-bottom: 4rem;
}

.lockImg {
  width: 70%;
}

.ovalIconCont {
  /* z-index: 999; */
  /* margin-right: 1.5rem; */
}

.profilePicImg {
  width: 50%;
}
.amal {
  background-color: var(--primary);
  color: white;

  height: 50%;
  border-radius: 3.2rem;
  padding: 3rem 2.4rem;
  display: flex;
  flex-direction: column;
}

.amalMainImageCont {
  /* background-color: olive; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.amalMainImage {
  margin-top: 4.5rem;
  margin-bottom: 4rem;
  width: 80%;
}

.empoweringCont {
  width: 100%;

  display: flex;
  flex-direction: column;
  padding: 15rem 12rem;
}

.empHeading {
  font-size: 3.8rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 6rem;
}

.empCardsCont {
  /* background-color: orange; */
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  align-items: center;
}

.empCardsContRow {
  /* background-color: aliceblue; */
  width: 100%;
  display: flex;
  align-items: center;
  gap: 2.4rem;
}

.empCardsContRow1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 3.2rem 4rem;
  /* background-color: brown; */
  border-radius: 3.2rem;
  box-shadow: 0px 2px 10px 0px #00000026;
}
.empCardsContRow2 {
  flex: 1;
  padding: 3.2rem 4rem;

  /* background-color: olive; */
  border-radius: 3.2rem;
  box-shadow: 0px 2px 10px 0px #00000026;
}

.empIconCont {
  display: flex;
  align-items: center;

  gap: 2.4rem;
  margin-bottom: 4rem;
}

.aspiringCont {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.aspiringText {
  font-size: 2.2rem;
  font-weight: 600;
}

.dreamText {
  font-size: 2rem;
  color: #727272;
}

.transformText {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 6rem;
}

.learnMoreEmp {
  font-size: 2rem;
  font-weight: 500;
  color: var(--primary);
}

.ourValuesCont {
  background-color: #000342;
  padding: 11rem 6rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.valuesText {
  font-size: 3.8rem;
  font-weight: 600;
  color: white;
  margin-bottom: 8.4rem;
  text-align: center;
}

.valuesCardsCont {
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
}

.valuesCard1 {
  background-color: white;
  border-radius: 1.6rem;
  padding: 2.5rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(3.01deg);
}
.valuesCard2 {
  background-color: white;
  border-radius: 1.6rem;
  padding: 2.5rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(1.74deg);
}
.valuesCard3 {
  background-color: white;
  border-radius: 1.6rem;
  padding: 2.5rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-1.9deg);
}
.valuesCard4 {
  background-color: white;
  border-radius: 1.6rem;
  padding: 2.5rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(0.93deg);
}

.barTextCont {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.bar1 {
  background-color: #e197a4;

  width: 0.8rem;
  height: 5rem;
  border-radius: 2rem;
  box-shadow: 0px 4px 7px 0px #ffffff59;
}
.bar2 {
  background-color: #80af8c;

  width: 0.8rem;
  height: 5rem;
  border-radius: 2rem;
  box-shadow: 0px 4px 7px 0px #ffffff59;
}
.bar3 {
  background-color: #845e73;

  width: 0.8rem;
  height: 5rem;
  border-radius: 2rem;
  box-shadow: 0px 4px 7px 0px #ffffff59;
}
.bar4 {
  background-color: #bb8ca3;

  width: 0.8rem;
  height: 5rem;
  border-radius: 2rem;
  box-shadow: 0px 4px 7px 0px #ffffff59;
}

.barText {
  font-size: 2.2rem;
  font-weight: 600;
}

.reviews {
  /* background-color: orange; */
  width: 100%;
  padding: 12rem 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imgHeadingCont {
  display: flex;
  align-items: center;
}

.quoteImgCont {
  width: 19.3rem;
  height: 19.3rem;
}

.cusText {
  font-size: 3.8rem;
  font-weight: 600;
  margin-bottom: 7rem;
  text-align: center;
}

.reviewsCardsCont {
  /* background-color: #80af8c; */
  display: flex;
  width: 100%;
  gap: 2rem;
}

.reviewsCard1 {
  width: 75%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 2rem;
}

.reviewsCard1cards {
  display: flex;
  gap: 1.5rem;
}

.reviewsCard2 {
  /* background-color: olive; */
  width: 25%;
  gap: 1.5rem;
}

.reviewsCard21 {
  background-color: #80af8c;
  width: 100%;
  height: 100%;
  border-radius: 3.2rem;
  padding: 3.1rem 3.3rem;
}

.reviewsCard11 {
  background-color: #e197a4;
  width: 60%;
  border-radius: 3.2rem;
  padding: 3.1rem 3.3rem;
}

.reviewsCard12 {
  background-color: #a3a294;
  width: 40%;
  border-radius: 3.2rem;
  padding: 3.1rem 3.3rem;
}

.reviewsCard13 {
  background-color: #bb8ca3;
  width: 40%;
  border-radius: 3.2rem;
  padding: 3.1rem 3.3rem;
}
.reviewsCard14 {
  background-color: #845e73;
  width: 60%;
  border-radius: 3.2rem;
  padding: 3.1rem 3.3rem;
}

.reviewsProfileCont {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  margin-bottom: 3.2rem;
}

.reviewsDpCont {
  width: 6.7rem;
  height: 6.7rem;
}

.nameDesCont {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.name {
  font-size: 2.2rem;
  font-weight: 600;
  color: white;
}

.desig {
  font-size: 2rem;
  font-weight: 500;
  color: #ffffff80;
}

.joinCont {
  /* background-color: #000342; */
  background-image: url("../../assets/images/joinBgImageMin.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* gap: 5rem; */
  padding: 12rem 2.5rem;
}

.joinText {
  font-size: 6.4rem;
  font-weight: 700;
  color: white;
  margin-bottom: 6rem;
  text-align: center;
}
.joinPara {
  font-size: 2rem;
  font-weight: 500;
  color: white;
  margin-bottom: 4.5rem;
}

.emailSubCont {
  background-color: white;
  width: 40%;
  display: flex;
  align-items: center;
  border-radius: 1.6rem;
}

.emailSub1 {
  /* background-color: aqua; */
  width: 75%;
  border-radius: 1.6rem 0 0 1.6rem;
  padding: 0 2.2rem;
}
.emailSub2 {
  background-color: var(--primary);
  width: 25%;
  border-radius: 1.6rem;
  padding: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.input {
  width: 100%;
  font-size: 1.8rem;
  font-weight: 500;
  color: #808080;
}

@media screen and (max-width: 767px) {
  .mainCont {
    /* padding: 4rem 2rem; */
  }
  .spansCont {
    gap: 0;
    padding: 4rem 2rem;
  }
  .fuelHeading1 {
    font-size: 4rem;
  }
  .fuelHeading2 {
    font-size: 4rem;
  }
  .weAscendCont {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 4rem 2rem;
  }

  .imgCont {
    padding: 4rem 2rem;
  }

  .topSec {
    padding: 4rem 2rem;
  }
  .bootcampCont {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* order: 5; */
  }

  .weAscend1Top {
    flex-direction: column;
    width: 100%;
  }

  .weAscend1Top1 {
    width: 100%;
  }

  .percentWsmesCont {
    flex-direction: column;
    width: 100%;
  }

  .weAscend1Bottom {
    flex-direction: column;
    width: 100%;
  }

  .weAscend2 {
    width: 100%;
  }
  .compass {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .circle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .credit {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .empoweringCont {
    padding: 4rem;
  }
  .empCardsContRow {
    flex-direction: column;

    width: 100%;
    gap: 0;
  }
  .empCardsCont {
    gap: 0;
  }
  .reviews {
    padding: 4rem;
  }
  .reviewsCard1cards {
    flex-direction: column;
    width: 100%;
  }

  .reviewsCardsCont {
    width: 100%;

    flex-direction: column;
  }

  .reviewsCard1 {
    width: 100%;
  }

  .reviewsCard11 {
    width: 100%;
  }

  .reviewsCard12 {
    width: 100%;
  }
  .reviewsCard13 {
    width: 100%;
  }
  .reviewsCard13 {
    width: 100%;
  }
  .reviewsCard14 {
    width: 100%;
  }
  .reviewsCard2 {
    width: 100%;
  }
  .reviewsCard21 {
    width: 100%;
  }

  .logoCont {
    padding: 7rem 4rem;
  }

  .ascendLogoWrap {
    /* background-color: orange; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logoCont:hover .ascendLogoWrap {
    width: 100%;
  }

  .emailSubCont {
    width: 100%;
  }
  .joinText {
    font-size: 4rem;
  }
  .lockImg {
    width: 40%;
  }
  .profilePicImg {
    width: 30%;
  }
  .womenInCirclesPlus {
    width: 12rem;
    margin-bottom: 3.2rem;
  }
  .percentCont {
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 2.5rem;
  }
  .empCardsContRow1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ovalIconCont {
    width: 30%;
  }
  .percentWsmesCont2 {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .estimated {
    /* font-size: 1.6rem; */
    text-align: center;
  }
  .cardPink {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .cardWhite {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .percentWsmesCont {
    /* order: 7; */
  }

  .victoryImgCont {
    width: 90%;
  }
  .estimated {
    font-size: 1.4rem;
  }
  .title {
    font-size: 1.8rem;
  }
  .titleWhite {
    font-size: 1.8rem;
  }
  .headline {
    /* font-size: 1.6rem; */
  }
  .cardWhite:hover {
    border: none;
  }
  .cardVictory:hover {
    border: none;
  }
  .cardPink:hover {
    border: none;
  }
  .percentWsmesCont1 {
    width: 90%;
  }
  .logoCont {
    width: 100%;
  }
  .amal {
    width: 90%;
  }
  .learnMoreCont {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
  }
  .learnMore {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    order: 4;
    text-align: center;
    padding: 0;
  }

  .growthIcon {
    display: flex;
    order: 1;
    margin-bottom: 1.7rem;
    margin-top: 2.4rem;
  }

  .learnMorePink {
    width: 100%;
    font-size: 1.6rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    order: 4;
  }

  .learnMoreWhite {
    width: 100%;
    font-size: 1.6rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    order: 4;
  }
}

.logoContainer {
  /* background-color: aqua; */
  width: 10%;
}

@media screen and (max-width: 767px) {
  .logoContainer {
    width: 18%;
  }
}

/* index.css */

/* @font-face {
  font-family: "myUrduFont";
  src: url("../src/assets/fonts/fajerNooriNastaliqueUrdu.ttf")
    format("truetype");
} */

:root {
  --primary: #ff0071;
  --secondary: #374aa9;
  --gray: #3f3f3f;
  --headline: #727272;
  --pink-dim: #df5693;
  --light: #fff;
  --primary-bg-dark: #333;
  --dark: #000;
  --border: #c4c4c4;
  --label: #6d6d6d;
  --alternate: #fafafa;
  --btn-bg: #358a184d;
  --keywords: #fbdbfe;
  --critical: #ffc9c9;
  --nonCritical: #cfffd8;
  --urduNews: #c4c2ff;
  --bgClr: #f5faff;
  --bgClrModal: #e1eeff;
}

html {
  font-size: 10px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

body {
  font-size: 1.6rem;
  font-family: "Inter";

  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

img {
  width: 100%;
}

ul {
  list-style-type: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

input {
  /* background-color: #d9d8f0; */
  /* width: 80%; */
}

label {
  margin-bottom: 1rem;
  font-weight: 700;
  color: var(--label);
}

input,
button,
select {
  border: none;
  outline: none;
  /* font-size: 1.6rem; */
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  font-size: 1.4rem;
}

a:hover {
  color: var(--primary);
}
strong {
  /* color: var(--primary); */
  color: black;
}
h1 {
  font-size: 2.4rem;
  margin-bottom: 1rem;
  color: var(--primary);
  /* text-align: center; */
}
h3 {
  margin-bottom: 1rem;
  color: #454545;
  /* text-align: center; */
}

button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  font-family: "Inter";
  cursor: pointer;
}

/* Button styling */
/* button {
  font-size: 1.6rem;
  font-weight: 700;

  padding: 0;
  background-color: var(--primary);
  color: white;
  padding: 0.8rem 1.6rem;
  border: none;
  outline: none;
  border-radius: 3rem;
  cursor: pointer;
  margin: 2rem 0;
}

button:hover {
  background-color: var(--secondary);
  color: var(--primary);
} */

/* svg {
  cursor: pointer;
} */

@media screen and (max-width: 767px) {
  body {
    font-size: 1.2rem;
  }

  button {
    font-size: 1.3rem;
    padding: 0.6rem 1rem;
  }
  h1 {
    font-size: 1.8rem;
    text-align: center;
  }

  a {
    font-size: 1rem;
  }

  h4 {
    white-space: nowrap;
  }
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* ValuesCards.module.css */

.valuesCardsCont {
  display: flex;
  flex-direction: column;
}

.valuesCard {
  background-color: white;
  border-radius: 1.6rem;
  padding: 2.5rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateY(20rem);
  transition: opacity 0.5s ease-in-out, transform 2s ease-in-out;
}

.card1.animateCard {
  opacity: 1;
  transform: translateY(0) rotate(-3.01deg);
}

.card2.animateCard {
  opacity: 1;
  transform: translateY(0) rotate(1.74deg);
}
.card3.animateCard {
  opacity: 1;
  transform: translateY(0) rotate(-1.9deg);
}

.card4.animateCard {
  opacity: 1;
  transform: translateY(0) rotate(0.93deg);
}

/* Add classes for other cards as needed */

.barTextCont {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.bar1 {
  background-color: #e197a4; /* Unique color for bar1 */
  width: 0.6rem;
  height: 5rem;
  border-radius: 2rem;
  box-shadow: 0px 4px 7px 0px #ffffff59;
}

.bar2 {
  background-color: #80af8c; /* Unique color for bar2 */
  width: 0.6rem;
  height: 5rem;
  border-radius: 2rem;
  box-shadow: 0px 4px 7px 0px #ffffff59;
}

.bar3 {
  background-color: #845e73; /* Unique color for bar3 */
  width: 0.6rem;
  height: 5rem;
  border-radius: 2rem;
  box-shadow: 0px 4px 7px 0px #ffffff59;
}

.bar4 {
  background-color: #bb8ca3; /* Unique color for bar4 */
  width: 0.6rem;
  height: 5rem;
  border-radius: 2rem;
  box-shadow: 0px 4px 7px 0px #ffffff59;
}

.barText {
  font-size: 2.2rem;
  font-weight: 600;
}

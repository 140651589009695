.mainCont {
  padding: 10rem 0rem;
}

.sec1 {
  /* background-color: orange; */
  display: flex;
  align-items: center;
  margin-bottom: 12rem;
  gap: 2rem;
  padding: 0 8rem;
}
.sec1a {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.sec1b {
  /* background-color: orange; */
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.unlockParaCont {
  margin-bottom: 12rem;
  padding: 0 8rem;
}

.unlockParaText {
  font-size: 3rem;
  font-weight: 500;
  text-align: center;
  color: #2d2d2d;
}

.grayBold {
  font-weight: 700;
  color: #2d2d2d;
}
.sec2 {
  /* background-color: olive; */

  display: flex;
  align-items: center;
  margin-bottom: 12rem;
  justify-content: space-between;
  gap: 10.9rem;
  padding: 0 8rem;
}
.sec2a {
  /* background-color: #595959; */
  width: 50%;
  display: flex;
  flex-direction: column;
}
.sec2b {
  /* background-color: orange; */
  width: 50%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.sec3b {
  /* background-color: orange; */
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.aspiring {
  font-size: 6.4rem;
  font-weight: 700;
  color: #ff0071;
  margin-bottom: 2.3rem;
  margin-top: 5rem;
}

.title {
  font-size: 3.8rem;
  font-weight: 600;
  margin-bottom: 5rem;
}

.headline {
  font-size: 2.4rem;
  font-weight: 500;
  color: #595959;
  margin-bottom: 2.6rem;
}

.desc {
  font-size: 2.4rem;
  font-weight: 500;
  color: #2d2d2d;
  margin-bottom: 8rem;
}
.ascendBtnWrap {
  /* background-color: orange; */

  display: flex;
}

.ascendBtnCont {
  background-color: var(--primary);

  padding: 1.5rem 2.5rem;
  border-radius: 1.6rem;
  color: white;
  margin-bottom: 8.2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.joinBtnText {
  font-size: 2rem;
  font-weight: 500;
  color: white;
}

.ascendBtnCont:hover {
  background-color: #ff2485;
}

.backArrow {
  width: 5.5rem;
}

.readyToTurn {
  font-size: 3.5rem;
  font-weight: 500;
  text-align: center;
  color: #595959;
}

.redyToCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6.4rem;
  padding: 15rem 13.1rem;
}

.howItWorksCont {
  background-image: url("../../assets/images/gradient.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10.8rem 8rem;
  margin-bottom: 12rem;
}

.howItWorksCardsCont {
  width: 100%;

  display: flex;
  /* align-items: center; */
  gap: 1.2rem;
}

.worksText {
  font-size: 3.8rem;
  font-weight: 700;
  color: white;
  text-align: center;
  margin-bottom: 9.5rem;
}

.howItWorksCard {
  background-color: white;
  flex: 1;
  border-radius: 2rem;
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
}

.orangeText {
  background-color: #ffe0dc;
  color: #ff6250;
  padding: 0.8rem 1.8rem;
  border-radius: 0.8rem;
  font-size: 2.3rem;
  font-weight: 600;
  /* margin-bottom: 2.4rem; */
}

.worksTitle {
  font-size: 2.3rem;
  font-weight: 600;
  margin-top: 2.4rem;
  margin-bottom: 1.4rem;
}

.worksDesc {
  font-size: 1.8rem;
  color: #2d2d2d;
  margin-bottom: 10rem;
}

.touchMainCont {
  /* background-color: orange; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  padding: 0 8rem;
}

.touchText {
  font-size: 5rem;
  font-weight: 700;
  margin-bottom: 7rem;
  text-align: center;
}

.contactCont {
  background-color: white;
  width: 100%;
  display: flex;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 1rem;
}

.contactCont1 {
  background-color: #000342;
  width: 40%;
  color: white;
  border-radius: 1rem;
  padding: 4rem;
  display: flex;
  flex-direction: column;
}

.contactText {
  font-size: 2.8rem;
  font-weight: 600;
  margin-bottom: 1.4rem;
  text-align: center;
}
.sayText {
  font-size: 1.8rem;
  margin-bottom: 11.6rem;
  color: #c9c9c9;
  text-align: center;
}
.contactCont2 {
  /* background-color: antiquewhite; */
  width: 60%;
  padding: 6rem 5rem;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  gap: 4.5rem;
}

.flexRow {
  display: flex;
  gap: 4rem;
}

.labelInputCont {
  /* background-color: orange; */
  width: 50%;
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 1.2rem;
  font-weight: 500;
  color: black;
  margin-bottom: 0;
}

.input {
  border-bottom: 1px solid #8d8d8d;
  padding: 1.1rem 0;
  color: black;
  font-weight: 500;
}

.areaCont {
  /* background-color: orange; */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
}

.area {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 0.8rem;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  padding: 1.2rem 1.5rem;
  font-family: Inter;
  font-size: 1.4rem;
  color: black;
}

.sendBtnCont {
  display: flex;
  justify-content: flex-end;
}

.sendBtn {
  background-color: var(--primary);
  padding: 1.5rem 4.8rem;
  color: white;
  border-radius: 1.2rem;
  font-weight: 500;
}

.sendBtn:hover {
  background-color: #ff2485;
}

.infoCont {
  /* background-color: orange; */
  display: flex;
  flex-direction: column;
  gap: 5.3rem;
}

.iconTextCont {
  display: flex;
  align-items: center;
  gap: 2.5rem;
}

.locCont {
  /* background-color: orange; */
  width: 16%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadCont {
  /* background-color: orange; */
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  border: 4px solid #ffffff; /* Light grey */
  border-top: 4px solid var(--primary); /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.smIconsWrap {
  /* background-color: olive; */
  height: 100%;
  display: flex;
  /* justify-content: flex-end; */
  align-items: flex-end;
}

.smIconsCont {
  display: flex;
  gap: 2.4rem;
}

.modal {
  /* background-color: blue; */
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 8888;
  padding-top: 6rem;
}

/* Optional: Set a maximum width for the modal */
.modalCont {
  background-color: var(--light);
  width: 50%;
  padding: 2rem 2rem;
  border: 1px solid var(--border);
  overflow: hidden;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.sureCont {
  /* background-color: var(--bgClrModal); */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem 0rem;
}

.sureTextCont {
  /* background-color: brown; */
  flex: 1;
  font-size: 2.4rem;
  font-weight: 600;
  color: var(--primary);
}

.crossIconCont {
  width: 5%;
  /* background-color: olive; */
  display: flex;
  align-self: flex-end;
  cursor: pointer;
}

.subImgCont {
  width: 25%;
}

.text {
  font-size: 1.8rem;
  color: #3c3c3c;
  padding: 2rem 2.5rem;
}

/* Optional: Style for the buttons in the modal */
.modalButtons {
  /* background-color: aqua; */
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 1.5rem;
  margin-bottom: 2.4rem;
}

.cancelBtn {
  background-color: transparent;
  color: var(--dark);
}
.cancelBtn:hover {
  background-color: transparent;
  color: var(--dark);
}

.exitBtn {
  background-color: transparent;
  color: var(--primary);
  border: 1px solid var(--primary);
}
.exitBtn:hover {
  background-color: var(--primary);
  color: var(--light);
  border: 1px solid var(--primary);
}

@media screen and (max-width: 767px) {
  .mainCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem 0rem;
  }
  .sec1 {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    padding: 0 2rem;

    /* gap: 5.4rem; */
  }
  .sec1a {
    /* background-color: orange; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    order: 2;
  }
  .sec1b {
    /* background-color: rgb(19, 12, 1); */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    order: 1;
  }
  .sec2 {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    gap: 5.4rem;
    padding: 0 2rem;
  }
  .sec2a {
    /* background-color: orange; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    order: 2;
  }
  .sec2b {
    /* background-color: rgb(19, 12, 1); */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    order: 1;
  }

  .sec3 {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    gap: 5.4rem;
  }
  .sec3a {
    /* background-color: orange; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    order: 2;
  }
  .sec3b {
    /* background-color: rgb(19, 12, 1); */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    order: 1;
  }
  .aspiring {
    font-size: 3.5rem;
    margin-top: 0;
    /* margin-bottom: 3.7rem; */
  }
  .title {
    margin-bottom: 2rem;
  }

  .howItWorksCont {
    padding: 4rem 2rem;
  }

  .howItWorksCardsCont {
    display: flex;
    flex-direction: column;
  }

  .unlockParaCont {
    padding: 0 2rem;
  }

  .redyToCont {
    padding: 6rem 2rem;
    gap: 4rem;
  }

  .weAscendLogoWcont {
    width: 80%;
  }
  .contactCont {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contactCont1 {
    width: 100%;
    align-items: center;
    padding: 4rem 2rem;
  }
  .contactCont2 {
    width: 100%;
    padding: 6rem 0rem;
  }
  .infoCont {
    /* background-color: orange; */
    align-items: center;
    gap: 1.8rem;
  }

  .iconTextCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;
    text-align: center;
  }

  .sayText {
    margin-bottom: 1.6rem;
  }

  .smIconsWrap {
    margin-top: 6.4rem;
  }

  .flexRow {
    display: flex;
    flex-direction: column;
  }

  .labelInputCont {
    width: 100%;
  }

  .sendBtnCont {
    justify-content: center;
    margin-top: 9rem;
  }
  .sendBtn {
    background-color: var(--primary);
  }

  .touchMainCont {
    padding: 0 2rem;
  }
  .locCont {
    /* width: 100%; */
  }

  .modalCont {
    width: 80%;
  }

  .text {
    padding: 2rem 0rem;
    font-size: 1.4rem;
  }
  .crossIconCont {
    width: 10%;
  }
  .subImgCont {
    width: 50%;
  }
  .sureTextCont {
    font-size: 1.8rem;
  }
}

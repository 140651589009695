.imgsCont {
  /* background-color: olive; */
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* height: 30vh; */
  padding: 1rem;
  margin-bottom: 10.5rem;
  margin-top: 8.5rem;
  padding: 4rem 0;

  /* z-index: 9999; */
}

.slider {
  /* background-color: beige; */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8rem;
  position: absolute;
  animation: slideLeft 12s linear infinite;
}

.bankImage1 {
  /* background-color: bisque; */
  width: 100%;
}

.bankImage2 {
  /* background-color: bisque; */
  width: 100%;
}
.bankImage3 {
  width: 100%;
}
.bankImage4 {
  /* background-color: bisque; */
  width: 100%;
}

@keyframes slideLeft {
  0% {
    transform: translateX(100%); /* Start position off-screen to the right */
  }
  100% {
    transform: translateX(-100%); /* End position off-screen to the left */
  }
}

@media screen and (max-width: 767px) {
  .imgsCont {
    height: 20vh;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
    margin-top: 0;
  }

  .slider {
    gap: 4rem;
  }

  .bankImage1 {
    /* background-color: bisque; */
    width: 18rem;
  }

  .bankImage2 {
    /* background-color: bisque; */
    width: 18rem;
  }
  .bankImage3 {
    width: 18rem;
  }
  .bankImage4 {
    /* background-color: bisque; */
    width: 18rem;
  }
}

.mainCont {
  padding: 10rem 0rem;
}

.sec1 {
  /* background-color: orange; */
  display: flex;
  align-items: center;
  margin-bottom: 12rem;
  gap: 2rem;
  padding: 0 8rem;
}
.sec1a {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.sec1b {
  /* background-color: orange; */
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.unlockParaCont {
  margin-bottom: 12rem;
  padding: 0 8rem;
}

.unlockParaText {
  font-size: 3rem;
  font-weight: 500;
  text-align: center;
  color: #2d2d2d;
}

.grayBold {
  font-weight: 700;
  color: #2d2d2d;
}
.sec2 {
  /* background-color: olive; */

  display: flex;
  align-items: center;
  margin-bottom: 12rem;
  justify-content: space-between;
  gap: 10.9rem;
  padding: 0 8rem;
}
.sec2a {
  /* background-color: #595959; */
  width: 50%;
  display: flex;
  flex-direction: column;
}
.sec2b {
  /* background-color: orange; */
  width: 50%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.sec3b {
  /* background-color: orange; */
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.aspiring {
  font-size: 6.4rem;
  font-weight: 700;
  color: #ff0071;
  margin-bottom: 2.3rem;
  margin-top: 5rem;
}

.title {
  font-size: 3.8rem;
  font-weight: 600;
  margin-bottom: 5rem;
}

.headline {
  font-size: 2.4rem;
  font-weight: 500;
  color: #595959;
  margin-bottom: 5.5rem;
}

.desc {
  font-size: 2.4rem;
  font-weight: 500;
  color: #2d2d2d;
  margin-bottom: 8rem;
}
.ascendBtnWrap {
  /* background-color: orange; */

  display: flex;
}

.ascendBtnCont {
  background-color: var(--primary);

  padding: 1.5rem 2.5rem;
  border-radius: 1.6rem;
  color: white;
  margin-bottom: 8.2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.joinBtnText {
  font-size: 2rem;
  font-weight: 500;
  color: white;
}

.ascendBtnCont:hover {
  background-color: #ff2485;
}

.backArrow {
  width: 5.5rem;
}

.readyToTurn {
  font-size: 3.5rem;
  font-weight: 500;
  text-align: center;
  color: #595959;
}

.redyToCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6.4rem;
  padding: 15rem 13.1rem;
}

.weAscendLogoWcont {
  width: 30%;
}

.howItWorksCont {
  background-image: url("../../assets/images/gradient.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10.8rem 8rem;
}

.howItWorksCardsCont {
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  gap: 1.2rem;
}

.worksText {
  font-size: 3.8rem;
  font-weight: 700;
  color: white;
  text-align: center;
  margin-bottom: 9.5rem;
}

.howItWorksCard {
  background-color: white;
  flex: 1;
  border-radius: 2rem;
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
}

.orangeText {
  background-color: #ffe0dc;
  color: #ff6250;
  padding: 0.8rem 1.8rem;
  border-radius: 0.8rem;
  font-size: 2.3rem;
  font-weight: 600;
  /* margin-bottom: 2.4rem; */
}

.worksTitle {
  font-size: 2.3rem;
  font-weight: 600;
  margin-top: 2.4rem;
  margin-bottom: 1.4rem;
}

.worksDesc {
  font-size: 1.8rem;
  color: #2d2d2d;
  margin-bottom: 10rem;
}

@media screen and (max-width: 767px) {
  .mainCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0rem 0rem;
  }
  .sec1 {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    padding: 8rem 2rem 2rem 2rem;
    /* gap: 5.4rem; */
  }
  .sec1a {
    /* background-color: orange; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    order: 2;
  }
  .sec1b {
    /* background-color: rgb(19, 12, 1); */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    order: 1;
  }
  .sec2 {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    gap: 5.4rem;
    padding: 0rem 2rem;
  }
  .sec2a {
    /* background-color: orange; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    order: 2;
  }
  .sec2b {
    /* background-color: rgb(19, 12, 1); */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    order: 1;
  }

  .sec3 {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    gap: 5.4rem;
  }
  .sec3a {
    /* background-color: orange; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    order: 2;
  }
  .sec3b {
    /* background-color: rgb(19, 12, 1); */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    order: 1;
  }
  .aspiring {
    font-size: 3.5rem;
    margin-top: 7rem;
    /* margin-bottom: 3.7rem; */
  }
  .title {
    margin-bottom: 2rem;
  }

  .howItWorksCont {
    padding: 4rem 2rem;
  }

  .howItWorksCardsCont {
    display: flex;
    flex-direction: column;
  }

  .unlockParaCont {
    margin-top: 2rem;
    padding: 0 2rem;
  }

  .redyToCont {
    padding: 6rem 2rem;
    gap: 4rem;
  }

  .weAscendLogoWcont {
    width: 80%;
  }
  .worksText {
    margin-bottom: 6.7rem;
  }
}

.mainCont {
  background-color: rgb(183, 231, 231);
  width: 100%;
  /* height: 100vh; */
  display: flex;
}
.sec1 {
  background-color: white;
  width: 4%;
  display: flex;
  justify-content: center;
  padding-top: 11rem;
}
.iconsCont {
  /* background-color: orange; */
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.roundedDivAct {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 0.4rem;
  background-color: #e5edff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.roundedDivInac {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 0.4rem;
  background-color: "transpaent";
  display: flex;
  justify-content: center;
  align-items: center;
}
.sec2 {
  background-color: #f7f9fe;
  flex: 1;
  padding: 0 10.5rem 10rem 4rem;
  height: 100vh;
}

.chatbotCont {
  /* background-color: orange; */
  padding-top: 6rem;
  width: 100%;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.customHr {
  border: none;
  height: 0.1rem;
  background-color: var(--border);
  margin: 2rem 0;
}
.customHr2 {
  border: none;
  height: 0.1rem;
  background-color: var(--border);
  margin: 0.5rem 0 2rem 0;
}

.cuccum {
  font-size: 1.2rem;
}

.allSecCont {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.upperTabCont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.6rem;
}

.tab1 {
  background-color: white;
  min-height: 11rem;
  flex: 1;
  padding: 2rem 1.7rem;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
}

/* .tab1Inside {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  } */
.tab2 {
  background-color: white;
  min-height: 11rem;

  flex: 1;
  padding: 2rem 1.7rem;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tab2in {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
  padding: 0 2rem;
}

.tab2out {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
  border-right: 1px solid gray;
  border-left: 1px solid gray;
  padding: 0 2rem;
}

.tab2net {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
  padding: 0 2rem;
}

.tab3 {
  background-color: white;
  min-height: 11rem;

  padding: 2rem 1.7rem;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tab3net {
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
  padding: 0 2rem;
}

.nameBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.viewBtn {
  color: #374aa9;
  background-color: transparent;
  margin: 0;
  padding: 0;
}
.tab4 {
  background-color: #e5edff;
  flex: 1;
  padding: 2rem 1.7rem;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  min-height: 11rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bankTransacCont {
  background-color: white;
  display: flex;
  padding: 2rem 1.7rem;
  gap: 2rem;
}

.bt1 {
  background-color: white;

  width: 60%;
}

.bt1BtnzCont {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 2rem;
}
.bt2 {
  flex: 1;
  /* background-color: orange; */
}

.piechartCont {
  /* background-color: orange; */
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.treeLineCont {
  /* background-color: #1b2a7a; */
  width: 100%;

  display: flex;
  /* align-items: center; */
  /* justify-content: space-between; */
  gap: 1.6rem;
}

.tl1 {
  background-color: white;
  width: 30%;
  padding: 2rem 1.7rem;
}

.tl2 {
  background-color: white;

  flex: 1;
  padding: 2rem 1.7rem;
}

.tmLegendsCont {
  display: flex;
  align-items: center;
  gap: 2.4rem;
  /* justify-content: center; */
}

.tmLegend {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.square1 {
  background-color: #1b2a7a;
  width: 1.6rem;
  height: 0.8rem;
}
.square2 {
  background-color: #374aa9;
  width: 1.6rem;
  height: 0.8rem;
}
.square3 {
  background-color: #8c9ade;
  width: 1.6rem;
  height: 0.8rem;
}

.ratiosBarCont {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  gap: 1.6rem;
}

.rb1 {
  background-color: white;
  width: 35%;
  padding: 2rem 1.7rem;
}

.rb2 {
  background-color: white;
  flex: 1;
  padding: 2rem 1.7rem;
}

.ratiosCont {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 4rem;
}

.ratioCont {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-weight: bold;
  color: #6d6d6d;
  /* font-size: 1.2rem; */
}

.value {
  font-weight: bold;
  color: #454545;
}

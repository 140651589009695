.mainCont {
  padding: 4rem 10rem;
}

.sec1 {
  /* background-color: orange; */
  display: flex;
  margin-bottom: 12rem;
  gap: 2rem;
}
.sec1a {
  width: 60%;
  display: flex;
  flex-direction: column;
}
.sec1b {
  /* background-color: orange; */
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sec2 {
  /* background-color: olive; */

  display: flex;
  align-items: flex-start;
  margin-bottom: 12rem;
  justify-content: space-between;
  gap: 10.9rem;
}
.sec2a {
  /* background-color: #595959; */
  width: 60%;
  display: flex;
  flex-direction: column;
}
.sec2b {
  /* background-color: orange; */
  width: 40%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.sec3b {
  /* background-color: orange; */
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.aspiring {
  font-size: 6.4rem;
  font-weight: 700;
  color: #ff0071;
  margin-bottom: 2.3rem;
  margin-top: 5rem;
}

.title {
  font-size: 3.8rem;
  font-weight: 600;
}

.headline {
  font-size: 2.6rem;
  font-weight: 500;
  color: #595959;
  margin-bottom: 3.5rem;
}

.desc {
  font-size: 2.4rem;
  font-weight: 500;
  color: #2d2d2d;
  margin-bottom: 8rem;
}
.ascendBtnWrap {
  /* background-color: orange; */

  display: flex;
}

.ascendBtnCont {
  background-color: var(--primary);

  padding: 1.5rem 2.5rem;
  border-radius: 1.6rem;
  color: white;
  margin-bottom: 8.2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.joinBtnText {
  font-size: 2rem;
  font-weight: 500;
  color: white;
}

.ascendBtnCont:hover {
  background-color: #ff2485;
}

.backArrow {
  width: 5.5rem;
}

.readyToTurn {
  font-size: 4rem;
  font-weight: 500;
  text-align: center;
  color: #595959;
}

.redyToCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7.1rem;
}

@media screen and (max-width: 767px) {
  .mainCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0rem 2rem;
    margin-bottom: 7rem;
  }
  .sec1 {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    /* gap: 5.4rem; */
  }
  .sec1a {
    /* background-color: orange; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    order: 2;
  }
  .sec1b {
    /* background-color: rgb(19, 12, 1); */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    order: 1;
  }
  .sec2 {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    gap: 5.4rem;
  }
  .sec2a {
    /* background-color: orange; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    order: 2;
  }
  .sec2b {
    /* background-color: rgb(19, 12, 1); */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    order: 1;
  }

  .sec3 {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    gap: 5.4rem;
  }
  .sec3a {
    /* background-color: orange; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    order: 2;
  }
  .sec3b {
    /* background-color: rgb(19, 12, 1); */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    order: 1;
  }
  .aspiring {
    font-size: 3.5rem;
    margin-top: 0;
    /* margin-bottom: 3.7rem; */
  }
  .title {
    margin-bottom: 2rem;
  }
  .ascendBtnWrap {
    margin-bottom: 7rem;
  }
}
